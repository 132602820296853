import * as React from "react"
import { images } from "../../data/variables"
import "./FeaturedProduct.scss"

interface Props {
  name: string
  link?: string
  features?: string[]
  category?: string
  type?: string
  description?: string
  shortDescription?: string
}

const FeaturedProduct: React.FC<Props> = ({
  name,
  link,
  features,
  category,
  type,
  description,
  shortDescription,
}) => {
  return (
    <div className="featured-product">
      <div className="inner d-flex">
        <div className="left">
          <div className="name">
            <h4>{name}</h4>
          </div>
          <div className="short-description d-flex flex-column">
            <h4>{type}</h4>
            <span>{shortDescription}</span>
          </div>
          <div className="description">
            <span>{description}</span>
          </div>
          <div className="link">
            <a className="button">Prodavnica</a>
          </div>
        </div>
        <div className="right">
          <div className="features">
            {features?.map((f, i) => (
              <Feature desc={f} key={i} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const Feature = ({ desc }) => {
  return (
    <div className="feature">
      <div className="inner d-flex">
        <div className="description">
          <span>{desc}</span>
        </div>
        <div className="checkmark">
          <div className="img-container">
            <img src={images.ticked} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedProduct
